import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
//zorro
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SidebarMenus } from '../../../core/consts/sidebar-items.const';
import { SidebarMenusInterface } from '../../../core/interfaces/sidebar-items.interface';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Router, RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    NzLayoutModule,
    NzIconModule,
    RouterModule,
    NzMenuModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  sidebarItems: SidebarMenusInterface[] = SidebarMenus;
  constructor(private router: Router) {}
  ngOnInit(): void {}

  isSelected(route: string): boolean {
    return route === this.router.url;
  }

  isChildSelected(id: number) {
    return this.sidebarItems[id]?.children.some(
      (item) => item.route === this.router.url
    );
  }
}
