import { Component, OnInit } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//zorro
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TokenManagementService } from '../../../core/services/token-managment.service';
import { IUserResponse } from '../../../core/interfaces/user.interface';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzIconModule,
    RouterModule,
    NzToolTipModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: IUserResponse;
  constructor(private tokenManagementService: TokenManagementService) {}

  ngOnInit(): void {
    this.getUserInfo()
  }

  getUserInfo() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logout() {
    this.tokenManagementService.removeAllSession();
    localStorage.removeItem('user');
  }
}
