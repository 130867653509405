import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ILoginRequest, ILoginResponse } from '../interfaces/auth.interface';
import { GenericDataResponse } from '../interfaces/generic-response.interface';
import { ResourceService } from './resource.service';
import { TokenManagementService } from './token-managment.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ResourceService<
  ILoginResponse,
  ILoginRequest
> {
  private readonly endPoint = '/auth/login';
  private isAuthorizedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    httpClient: HttpClient,
    private tokenManagementService: TokenManagementService
  ) {
    super(httpClient);
  }

  login(data: ILoginRequest): Observable<GenericDataResponse<ILoginResponse>> {
    return this.post(this.endPoint, data).pipe(
      tap((res: GenericDataResponse<ILoginResponse>) => {
        this.tokenManagementService.setToken(res.response.token);
        localStorage.setItem('user', JSON.stringify(res.response.user));
      })
    );
  }

  get isAuthorized$() {
    this.tokenManagementService.hasToken()
      ? this.setAuthorized(true)
      : this.setAuthorized(false);
    return this.isAuthorizedSubject.asObservable();
  }

  setAuthorized(isAuthorized: boolean) {
    this.isAuthorizedSubject.next(isAuthorized);
  }
}
