<nz-header class="mb-2">
  <div class="flex flex-row justify-end gap-2 text-white">
    <p class="self-center mb-0 mt-1 pr-2 border-r-2 border-gray-4">Welcome {{ user?.name }}!</p>
    <button
      nzTooltipTitle="Logout"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      (click)="logout()"
    >
      <span nz-icon nzType="logout" nzTheme="outline"></span>
    </button>
  </div>
</nz-header>
