import { SidebarMenusInterface } from '../interfaces/sidebar-items.interface';

export const SidebarMenus: SidebarMenusInterface[] = [
  {
    id: 0,
    level: 1,
    name: 'Dashboard',
    icon: 'dashboard',
    route: '/dashboard',
  },
  {
    id: 1,
    level: 1,
    name: 'Users',
    icon: 'team',
    children: [
      {
        id: 10,
        level: 2,
        name: 'Subscribers',
        icon: 'usergroup-add',
        route: '/users/subscribers',
      },
      {
        id: 11,
        level: 2,
        name: 'Users',
        icon: 'user',
        route: '/users/users',
      },
      // {
      //   id: 12,
      //   level: 2,
      //   name: 'Drivers',
      //   icon: 'car',
      //   route: '',
      // },
    ],
  },
  {
    id: 2,
    level: 1,
    name: 'Quotes',
    icon: 'account-book',
    route: '/quotes/quotes-list',
  },
  {
    id: 3,
    level: 1,
    name: 'Website',
    icon: 'global',
    children: [
      {
        id: 30,
        level: 2,
        name: 'FAQ',
        icon: 'question-circle',
        route: '/website/faq',
      },
      {
        id: 31,
        level: 2,
        name: 'Blog',
        icon: 'edit',
        route: '/website/blog',
      },
      {
        id: 32,
        level: 2,
        name: 'Contact us',
        icon: 'contacts',
        route: '/website/contact-us',
      },
      {
        id: 33,
        level: 2,
        name: 'Privacy policy',
        icon: 'file-protect',
        route: '/website/privacy-policy',
      },
      {
        id: 34,
        level: 2,
        name: 'Items',
        icon: 'appstore',
        route: '/website/items',
      },
      {
        id: 35,
        level: 2,
        name: 'Jobs',
        icon: 'solution',
        route: '/website/jobs',
      },
    ],
  },
  {
    id: 4,
    level: 1,
    name: 'Orders',
    icon: 'account-book',
    route: '/orders/orders-list',
  },
  {
    id: 5,
    level: 1,
    name: 'System',
    icon: 'setting',
    children: [
      {
        id: 50,
        level: 2,
        name: 'Tax',
        icon: 'percentage',
        route: '/system/tax',
      },
      {
        id: 51,
        level: 2,
        name: 'Service',
        icon: 'tool',
        route: '/system/service',
      },
    ],
  },
  {
    id: 6,
    level: 1,
    name: 'Tasks',
    icon: 'layout',
    route: '/tasks/task-list',
  },
];
