import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AuthService } from './core/services/auth.service';
import { TokenManagementService } from './core/services/token-managment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthorize: boolean = this.tokenManagementService.hasToken();
  isAuthorized$ = this.authService.isAuthorized$;
  title: string;

  constructor(
    private tokenManagementService: TokenManagementService,
    private authService: AuthService,
    private location: Location,
    public titleService: Title
  ) {}

  ngOnInit() {}

  back() {
    this.location.back();
  }
}
