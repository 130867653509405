import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationHandlerService } from '../services/helper/notification-handler.service';
import { Router } from '@angular/router';

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationHandlerService: NotificationHandlerService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // if (event instanceof HttpResponse) {
          //   const method = request.method;
          //   const successMessageMethods = ["POST", "PUT", "PATCH"];
          //   if (successMessageMethods.some(m => m === method) &&
          //     event.body?.succeeded == true &&
          //     event.body?.message) {
          //     // this.notificationHandler.showSuccess(event.body.message);
          //   }
          // }
        },
        (err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // this.router.navigateByUrl('/auth/login');
              // return
            }
            this.notificationHandlerService.showNotification(
              'error',
              'Error',
              err.error.message
            );
          }
        }
      )
    );
  }
}
