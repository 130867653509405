<ng-container *ngIf="isAuthorized$ | async; else authTemplate">
  <nz-layout>
    <nz-sider>
      <app-sidebar></app-sidebar>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <app-header></app-header>
      </nz-header>
      <nz-content class="mt-2 px-4 pb-2">
        <div class="flex justify-start gap-2 my-2 py-2">
          <button type="button" (click)="back()">
            <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
          </button>
          <h4>{{ titleService.getTitle() }}</h4>
        </div>
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-container>
<ng-template #authTemplate>
  <router-outlet></router-outlet>
</ng-template>
