import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { list } from 'postcss';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'users',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'quotes',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/quotes/quotes.module').then((m) => m.QuotesModule),
  },
  {
    path: 'orders',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'website',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/website/website.module').then((m) => m.WebsiteModule),
  },
  {
    path: 'system',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/system/system.module').then((m) => m.SystemModule),
  },
  {
    path: 'tasks',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
  },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
