<nz-sider class="fixed h-full overflow-y-hidden">
  <div>
    <div class="border-b-2 border-gray-5 py-3">
      <img class="px-5" src="../assets/images/logos/logo_light_sm.svg" alt="Prestige Logo" />
    </div>
    <div class="overflow-y-auto scrollbar">
      <ul nz-menu nzTheme="dark" nzMode="inline" class="py-3">
        <ng-container *ngFor="let menu of sidebarItems">
          <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { menu: menu }"></ng-container>
          <ng-template #recursiveListTmpl let-menu="menu">
            <li *ngIf="menu.children" nz-submenu [nzOpen]="isChildSelected(menu.id)" [nzTitle]="menu.name"
              [nzIcon]="menu.icon">
              <ul>
                <ng-container *ngFor="let child of menu.children">
                  <ng-container *ngTemplateOutlet="
                        recursiveListTmpl;
                        context: { menu: child }
                      "></ng-container>
                </ng-container>
              </ul>
            </li>
            <li *ngIf="!menu.children" nz-menu-item [nzPaddingLeft]="menu.level * 24" [routerLink]="menu.route"
              [nzMatchRouterExact]="true" [nzSelected]="isSelected(menu.route)">
              <span nz-icon [nzType]="menu.icon" nzTheme="outline"></span>
              <span>{{ menu.name }}</span>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </div>
  </div>
</nz-sider>