import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

//components
import { AppComponent } from './app.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';

//interceptors
import { RequestErrorInterceptor } from './core/interceptors/request-error.interceptor';
import { RequestHeaderInterceptor } from './core/interceptors/request-header.interceptor';

//ng-zorro
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  AccountBookFill,
  AlertFill,
  AlertOutline,
  UserOutline,
  LockOutline,
  MailOutline,
  DashboardOutline,
  LogoutOutline,
  AccountBookOutline,
  GlobalOutline,
  ContactsOutline,
  QuestionCircleOutline,
  EditOutline,
  FileProtectOutline,
  DeleteOutline,
  SendOutline,
  MinusCircleOutline,
  EyeOutline,
  ArrowLeftOutline,
  PlusOutline,
  SolutionOutline,
  SnippetsOutline,
  LayoutOutline
} from '@ant-design/icons-angular/icons';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';

const icons: IconDefinition[] = [
  AccountBookFill,
  AlertOutline,
  AlertFill,
  UserOutline,
  LockOutline,
  MailOutline,
  DashboardOutline,
  LogoutOutline,
  AccountBookOutline,
  GlobalOutline,
  ContactsOutline,
  QuestionCircleOutline,
  EditOutline,
  FileProtectOutline,
  DeleteOutline,
  SendOutline,
  MinusCircleOutline,
  EyeOutline,
  ArrowLeftOutline,
  PlusOutline,
  SolutionOutline,
  SnippetsOutline,
  LayoutOutline
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    SidebarComponent,
    HeaderComponent,
    BreadcrumbComponent,
    NzLayoutModule,
    NzIconModule.forRoot(icons),
    NzModalModule,
    NzNotificationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
